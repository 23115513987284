@font-face {
  font-family: "SFPRO-REGULAR";
  src: url(../public/fonts/SFPRO/SFPRODISPLAYREGULAR.OTF);
  font-style: normal;
}

@font-face {
  font-family: "SFPRO-MEDIUM";
  src: url(../public/fonts/SFPRO/SFPRODISPLAYMEDIUM.OTF);
  font-style: normal;
}

body {
  margin: 0;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #f8f9fB; 
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #ededed; 
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9a9a9a; 
}

.normal {
  margin: 0;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fB;
}

.header {
  width: 100%;
  height: 60px;
  position: fixed;
  padding: 40px 15px 10px 15px;
  top: 0px;
  z-index: 10;
  background-color: #ffffff;
}

.footer {
  width: inherit;
  height: fit-content;
  text-align: center;
  bottom: 0px;
  z-index: 5;
  background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 100%);
  border-top: solid 1px #ededed;
}

.viewport-mobile {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  z-index: 15;
  background-color: #ffffff;
  border-bottom: solid 1px #ededed;
  text-align: center;
}

.viewport-desktop {
  width: 750px;
  height: 70px;
  position: fixed;
  z-index: 15;
  background-color: #ffffff;
  border-bottom: solid 1px #9a9a9a;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  text-align: center;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9a9a9a;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9a9a9a;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #9a9a9a;
}

.spinning {
  height: fit-content;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinning {
    animation: spin infinite 2s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlightable {
  opacity: 100%;
}

.highlightable:hover {
  opacity: 50%;
}

.sub-text {
  color: #9a9a9a;
}

.heading-0 {
  margin: 0;
  font-size: 50px;
  letter-spacing: 2px;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 
    sans-serif;
}

.heading-1 {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 
    sans-serif;
}

.heading-2 {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 
    sans-serif;
}

.bold {
  letter-spacing: 1px;
  margin: 10px 0px;
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 
    sans-serif;
}

.button-bare {
  background-color: rgba(0,0,0,0);
  color: #16191a;
  border:none;
  border-radius: 12px;
  padding: 0px 20px;
  width: 100%;
}

.button-light {
  background-color: #e7eaef;
  color: #16191a;
  border:none;
  border-radius: 12px;
  padding: 0px 30px;
  width: 100%;
}

.button-dark {
  background-color: #16191a;
  color: #ffffff;
  border:none;
  border-radius: 12px;
  padding: 0px 30px;
  width: 100%;
}

.button-dark2 {
  background-color: #16191a;
  color: #ffffff;
  border:none;
  border-radius: 12px;
  padding: 0px 20px;
}

.button-profile {
  background-color: #16191a;
  color: #ffffff;
  border:none;
  border-radius: 8px;
  padding: 0px 10px;
  height:30px;
  width: 100%;
}

.feed-button-active {
  background-color: #16191a;
  color: #ffffff;
  border: solid 1px #16191a;
  border-radius: 16px;
  padding: 0px 12px;
  height:30px;
  width: 100%;
}

.feed-button-inactive {
  background-color: #ffffff;
  border: solid 1px #9a9a9a;
  color: #16191a;
  border-radius: 16px;
  padding: 0px 12px;
  height:30px;
  width: 100%;
}

.event-button-pending {
  background-color: #f8f9fb;
  color: #16191a;
  border:none;
  border-radius: 40px;
  padding: 0px 30px;
  width: 100%;
}

.event-button-yes {
  background-color: #27c888;
  color: #ffffff;
  border:none;
  border-radius: 40px;
  padding: 0px 25px;
  width: 100%;
}

.event-button-maybe {
  background-color: #909a9f;
  color: #ffffff;
  border:none;
  border-radius: 40px;
  padding: 0px 25px;
  width: 100%;
}

.event-button-no {
  background-color: #f3647c;
  color: #ffffff;
  border:none;
  border-radius: 40px;
  padding: 0px 25px;
  width: 100%;
}

.card-blank {
  border: solid 1px #ffffff;
  border-radius: 12px;
  background-color: #ffffff;
  margin: 10px 0px;
  width: 100%;
}

.card-active {
  border: solid 1px #e7eaef;
  border-radius: 12px;
  background-color: #f8f9fb;
  margin: 10px 0px;
  width: 100%;
}

.card-inactive {
  border: solid 1px #9a9a9a;
  border-radius: 12px;
  margin: 10px 0px;
  width: 100%;
}

.card-white {
  border: solid 1px #ededed;
  border-radius: 12px;
  background-color:#ffffff;
  margin: 10px 0px;
  width: 100%;
}

.card-shadow {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 12px;
  margin: 10px 0px;
  width: 100%;
}

.feed-header {
  position: fixed;
  top: 70px;
  margin: auto;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px #ededed;
}

#browse-files {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#browse-files-label {
  font-family: SFPRO-REGULAR, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #16191a;
  color: #ffffff;
  border:none;
  border-radius: 8px;
  margin: 20px auto;
  padding: 0px;
  height:30px;
  width: 300px;
}

input {
  outline: none;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}